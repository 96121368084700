.PieChart{
    //background: var( --white );
    //background: var( --chartBackground );
    
    .apexcharts-datalabels-group{
        translate: (0, 9px) scale(1, 1);
    }
    .onCenter{
        display: flex;
    }
}
.onCenter{
    
}
.today{
    
}