.tableBlock{
    //display: flex;
    > div{
        display: block;
        background: var( --background );
        border-radius: 8px;
        padding: 12px;
    }
    .tableHeaderText{
        font-size: 14px;
        color: var( --caption );
        margin-left: 12px;
    }
    table{
        width: 100%;
        display: flex;
        flex-flow: column;
        background: var( --tableItem );
        border-collapse: collapse;
        tbody, thead tr{
            display: table;
            table-layout: fixed;
        }
        thead{
            flex: 0 0 auto;
            th{
                text-transform: uppercase;
                font-weight: 600;
                white-space: nowrap;
                text-overflow: ellipsis;
                //overflow: hidden;
                cursor: default;
                div > button > span{
                    text-transform: uppercase;
                    font-weight: 600;
                    color: var( --caption );

                    //overflow: hidden;
                }
            }
            
            //width: calc(100% - 0.9em);
        }
        thead > tr{
            width: 100%;
            height: 42px;
            font-size: 12px;
            font-weight: 300;
            th{
                white-space: break-spaces;
                //text-transform: capitalize;
            }
        }
        tbody{
            flex: 1 1 auto;
            display: block;
            overflow-y: auto;
            overflow-x: clip;
        }
        tbody > tr{
            width: 100%;
            height: 44px;
            display: table;
            table-layout: fixed;
            > td{
                font-size: 12px;
                word-break: break-word;
            }
            > td:first-child{
                text-align: center;
            }
        }
        tr > td, tr > th{
            border: 2px solid var( --background );
            padding: 0 8px;
            color: var( --caption )
        }
        .SelectedRow{
            background: var(--selectedTableItem);
        }
    }
    .TableTitle{
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
    }
}

@media only screen and (max-device-width: 1600px), screen and (max-width: 1600px) {
    .tableBlock{
        table thead th, table thead th span{
            font-size: 10px;
        }
    }
    .ShortTable td[data-hidden="hidden"], .ShortTable th[data-hidden="hidden"]{
        display: none;
    }
    .ShortTable td:last-child > div button:nth-child(3), .ShortTable td:last-child > div button:nth-child(4){
        margin: 4px 0 4px 12px;
    }
    .ShortTable td:last-child, .ShortTable th:last-child{
        width: 80px;
    }
    .ShortTable td:last-child > div{
        display: flex;
        flex-flow: row wrap;
        padding: 6px 0 6px 0;
        button:nth-child(odd){
            margin-left: 0;
        }
    }
    
}