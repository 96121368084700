.Dropdown{
    display: flex;
    flex-direction: column;
    position: relative;
    margin-left: 8px;
    button{
        display: flex;
        justify-content: space-between;
    }
}
.Header{
    //width: 54px;
    height: 32px;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 8px;
    background: none;
    white-space: nowrap;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    svg:not(:first-child){
        width: 8px;
        margin-left: 12px;
        path[data-path="fill"]{
            stroke: var( --headerCaption );
        }
        path[data-path="stroke"]{
            stroke: var( --headerCaption);
        }
    }
    svg:first-child{
        path[data-path="fill"]{
            fill: var( --headerCaption );
        }
        path[data-path="stroke"]{
            stroke: var( --headerCaption);
        }
    }
    span{
        color: var( --headerCaption);
        overflow: hidden;
    }
}
.Header:hover{
    background: var(--selectedButton);
    span{
        color: var(--selectedButtonText) !important;
    }
    svg:not(:first-child){
        width: 8px;
        margin-left: 12px;
        path[data-path="fill"]{
            stroke: var( --selectedButtonText );
        }
    }
    svg:first-child{
        path[data-path="fill"]{
            fill: var( --selectedButtonText );
        }
    }
}

.Body{
    position: absolute;
    background: var(--modalBackground);
    border-radius: 8px;
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    right: 0;
    box-shadow: 0px 0px 15px #00000055;
    z-index: 100;
    .itemButton{
        width: 100%;
        height: 32px;
        display: flex;
        align-items: center;
        background: none;
        border: none;
        cursor: pointer;
        svg{
            min-width: 32px;
        }
        span{
            margin-right: 8px;
            white-space: nowrap;
            color: var( --caption );
            font-size: 12px;
        }
    }
    .itemButton:first-child{
        border-radius: 8px 8px 0 0;
    }
    .itemButton:last-child{
        border-radius: 0 0 8px 8px;
    }

    .itemButton.selected, .itemButton:hover{
        background: var(--selectedDropdown);
        color: var( --selectedDropdownText );
        span{
            color: var( --selectedDropdownText );
        }
        svg{
            path[data-path="fill"]{
                fill: var( --selectedDropdownText );
            }
            path[data-path="stroke"]{
                stroke: var( --selectedDropdownText );
            }
        }
    }
}
