.SearchText{
    background: var( --background );
    border-radius: 8px;
    padding: 8px;
    margin-top: 24px;
    box-shadow: 0px 0px 15px #00000055;
    .SearchList{
        max-height: 185px;
        overflow-y: auto;
    }
    .TextBlock{
        display: flex;
        .searchButton{
            margin-left: 12px;
        }
    }
    .parentParent{
        margin-left: 4px;
        span{
            font-size: 12px;;
        }
    }
    .parent{
        margin-left: 12px;
        span{
            font-size: 12px;;
        }
    }
    .child{
        display: flex;
        align-items: center;
        margin-left: 24px;
        margin-right: 12px;

    }
    .selectedItem{
        background: var( --blue );
        border-radius: 4px;
        color: var( --white );
    }
    .notSelected{
        background: var( --purple );
        
    }
    .searchRow{
        display: flex;
        align-items: center;
    }
    .removeButton{
        width: 10px;
        height: 10px;
        display: flex;
        align-items: center;
        margin-left: 8px;
        cursor: pointer;
        svg{
            width: 10px;
            height: 10px;
        }
    }
    .searchTextItem{
        padding: 2px 4px;
        font-size: 12px;
        margin-bottom: 1px;
        cursor: pointer;
    }
    .ValidationBlock{
        display: flex;
        align-items: center;
        height: 32px;
        font-size: 12px;
        color: var( --red );
    }
    .blueText{
        color: var(--blue);
        cursor: pointer;
    }
}