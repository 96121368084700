.Keywords{
    width: 100%;
    .KeywordsHeader{
        display: flex;
        justify-content: space-between;
    }
    .Row{
        display: flex;
        justify-content: space-between;
        button{
            margin-left: 10px;
        }
    }
    .pasteKeywords{
        max-height: 200px;
        background: var( --white );
        border: 1px solid var( --gray );
        border-radius: 8px;
        padding: 8px;
        overflow-y: auto;
        > input{
            padding: 2px 4px;
            border: none;
            border-bottom: 1px solid var( --gray );
        }
        > input:focus, input:active{
            outline: none;
        }
        textarea{
            height: 20px;
            width: calc(100% - 8px);
            border: none;
            border-bottom: 1px solid var( --gray );
            resize: none;
            word-wrap: none;
            white-space: nowrap;
            overflow-x: scroll;
            scrollbar-width: none;
            font-size: 10px;
        }
          
        textarea::-webkit-scrollbar {
            display: none;
          }
        textarea:focus, textarea:active{
            outline: none;
        }
        .nonCheckedInput{
            color: var( --red );
        }
    }

    .deleteKeyword{
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--background);
        border: none;
        border-radius: 3px;
        padding: 4px;
        cursor: pointer;
    }
    .KeywordsList{
        table{
            width: 96%;
            th{
                color: var(--gray)
            }
            th, td{
                font-size: 10px;
                background: var(--tableItem);
                padding: 4px 8px;
                color: var( --caption );
            }
            th:first-child, td:first-child, th:last-child, td:last-child{
                min-width: 16px;
                width: 16px;
                max-width: 16px;
            }
            th:nth-child(2), td:nth-child(2){
                width: 100%
            }
        }
        margin-top: 12px;
        .keywordsBody{
            max-height: 112px;
            overflow-y: auto;
            table{
                width: 96%;
            }
        }
        .scrollBody{
            table{
                width: 100%;
            }
        }
    }
    .KeywordsMessage{
        margin: 8px 0;
        font-size: 12px;
        color: var( --blue );
    }
    .errorMessage{
        color: red;
        font-size: 12px;
    }
}
.BigWindow{
    width: 700px;
    height: 400px;
    .KeywordsList {
        table{
            width: 98.5%;
        }
        .keywordsBody{
            max-height: 332px;
            overflow-y: auto;
            table{
                width: 98.5%;
            }

        }
        .scrollBody{
            table{
                width: 100%;
            }
        }
        margin-top: 12px;
    }

    table th, table td{
        font-size: 14px;
    }
}