.chartItem{
    display: flex;
    justify-content: space-between;
    .arrow{
        font-size: 32px;
        line-height: 0;
    }
    .ChartBlock{
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg{
            height: 140px;
            foreignObject{
                width: 20px;
                height: 20px;
            }
        }
    }
    .leftChartBlock, .rightChartBlock{
        width: 35px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .leftChartBlockBottom, .rightChartBlockBottom{
            display: flex;
            flex-direction: column;
        }
        .leftChartBlockTop{
            display: flex;
            flex-direction: column;
            .WeekCount{
                display: flex;
                font-size: 20px;
                color: var( --caption)
            }
        }
        .leftChartBlockBottom{
            align-items: flex-start;
            div{
                display: flex;
                align-items: center;
            }
            .KeyValues span{
                font-size: 18px;
                margin-bottom: 5px;
            }
            .green{
                color: var( --green);
            }
            .gray{
                color: var( --gray);
            }
            .blue{
                color: var( --blue) 
            }
            .red{
                color: var( --red );
            }
        }
        .rightChartBlockTop{
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            span{
                color: var( --caption ); 
            }
        }
        .rightChartBlockBottom{
            align-items: flex-end;
            .totalNegativeAndPublic{
                display: flex;
                align-items: center;
                span{
                    margin-left: 4px;
                }
            }
            .small_values{
                font-size: 12px;
            }
        }
        .chartItemCaption{
            font-size: 7px;
            text-transform: uppercase;
            color: var( --caption );
        }
    }


    .rightChartBlock{
        align-items: flex-end;
    }
}
.AnalyticItem{
    height: calc(160px - 24px);
    width: calc(100% - 24px);      
    background: var( --chartBackground );
    border-radius: 8px;
    padding: 12px;
    margin-right: 12px;
    position: relative; 
}
.AnalyticItemMobile{
    width: 100%;
    .topChartBlockTop{
        display: flex;
        align-items: flex-end;
        background: var( --chartBackground);
        padding: 4px 8px;
        border-radius: 8px;
        .KeyValues{
            margin-left: 12px;
        }
    }
    .topChartBlock{
        display: flex;
        align-items: flex-end;
    }
    .ChartBlockTop{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .WeekCount{
        width: 100%;
        display: flex;
        margin-left: 12px;
    }
    span.chartItemCaption{
        font-size: 10px;
    }
    .Chart{
        width: 150px;
        height: 110px;
        position: relative;
        .PieChartBlock{
            position: absolute;
            left: -30px;
            top: -10px;
        }
    }

}



