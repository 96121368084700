.DropdownWithSearch{
    width: calc(100% - 24px);
    background: var( --background );
    padding: 12px;
    border-radius: 8px;
    .DropdownWithSearchHeader{
        width: 100%;
        
        > *{
            margin-bottom: 12px;
        }
        .HeaderTop{
            width: 100%;
            display: flex;
            justify-content: space-between;
            height: 18px;
            .rightBlock{
                display: flex;
                align-items: center;
                height: auto;
                > *{
                    margin-left: 12px;
                } 
            }
            span{
                color: var( --caption )
            }
        }
        .ChooseAll{
            display: flex;
            font-size: 12px;
            span{
                margin-left: 12px;
                color: var( --caption)
            }
            
        }
    }
    .DropdownWithSearchItemsBody{
        min-height: 110px;
        height: 130px;
        max-height: 200px;
        border-top: 1px solid var(--gray);
        overflow-y: auto;
    }
    .withChild{
        margin-left: 12px;
    }
}