.DigestMonthDays{
    .DigestRow{
        display: flex;
        justify-content: space-between;
        input{
            width: 120px;
        }

    }
    .DaysBlock{
        max-height: 165px;
        overflow-y: auto;
        display: flex;
        flex-flow: row wrap;
        
    }
}