.CheckboxList{
    width: calc(100% - 24px);
    .buttonsBlock{
        display: flex;
        justify-content: space-between;
        margin: 12px 0;
    }
    .item{
        display: flex;
        align-items: center;
        span{
            margin-left: 12px;
            color: var( --caption )
        }
    }
}