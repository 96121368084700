.Instruction{
    display: flex;
    flex-direction: column;
    width: 650px;
    background: var( --background );
    table{
        background: var( --white );
        border-collapse: collapse;
        thead{
            td{
                text-align: center;
            }
        }
        tr td{
            padding: 8px 4px;
            border: 1px solid var( --background );
        }
        tbody{
            tr:nth-child(odd) > td:first-child{
                text-align: center;
            }
        }
    }
    .minusTypeList{
        list-style-type: "- ";
        font-size: 14px;
        ul{
            list-style-type: "- ";
        }
    }
}