@import url('./assets/fonts/fonts.css');


html, body{
    width: 100%;
    height: 100%;
    overflow: hidden;
}

body {
    margin: 0;
    background: var( --backgroundColor);
}

a, h1, h2, h3, p, span, div, button, input, textarea{
    font-family: 'Rubik';
}

#root{
    height: 100%;
}
.formCol{
    display: flex;
    flex-direction: column;
}
.formRow > .labelText, .formCol > .labelText{
    display: flex;
    align-items: center;
    font-size: 10px;
    color: var( --echoHeader );
    text-transform: uppercase;
    margin-bottom: 8px;
    >:first-child{
        margin-right: 4px;
    }
}
.formRow.spaceBetween{
    display: flex;
    justify-content: space-between;
}
.formRow.spaceAround{
    display: flex;
    justify-content: space-around;
}
.formRow.center{
    display: flex;
    justify-content: center;
}
.formRow.alignCenter{
    display: flex;
    align-items: center;
}
.formRow:not(:last-child){
    margin-bottom: 24px;
}
.uppercase{
    text-transform: uppercase;
}

.active, .active:hover{
    background: var( --selectedButton ) !important;
    fill: white;
    color: white !important;
    border: none;
    border-radius: 8px;  
}
a.active, a.active span{
    color: var(--selectedButtonText) 
}
.active svg path[data-path="fill"], .active svg circle[data-circle="fill"]{
    fill: var( --selectedButtonText ) !important;
}
.active svg path[data-path="stroke"]{
    stroke: var( --selectedButtonText ) !important;
}  
.active:hover{
    background: var( --selectedButton );
    color: black;
}

.apexcharts-legend-series{
    white-space: break-spaces;
    word-break: break-all;
    max-width: 200px;
}
.Echo svg .apx-legend-position-right{
    top: 10% !important;
}

.onCenter{
    .apexcharts-datalabels-group{
        margin-bottom: 20px;
        position: absolute;
        top: -20px;
        display: flex;
        flex-direction: row;
        text:nth-child(2){
            font-size: 32px;
        }
        transform: translate(0, 0) scale(1, 1);
    }
}


:root {
    --white: #ffffff;
    --black: #000000;
    --darkBlue: #134E8D;
    --mediumBlue: #3373B7;
    --lightBlue: #97bbe2;
    --blure: #8AC3FF;
    --matBlure: #60B9E1;
    --lightGray: #E8ECF3;
    --backgroundLight: #E8ECF3;
    --gray: #9AAAB5;
    --grey: #C6C6D1;
    --captionText: #818290;
    --purple: #5555A0;
    --lightPurple: #595988;
    --matPurple: #A589D4;
    --mediumPurple: #40406C;
    --darkPurple: #1D1D46;
    --red: rgb(255, 0, 0);
    --matRed: #EE6766;
    --blue: #187BE4;
    --matBlue: #3E79BF; 
    --green: #00CA5F;
    --matGren: #B5DD5F;
    --orange: #F58219;
    --yellow: #EAE460;
    --echoHeader: #445067;
    --echoBackground: #F2F9FF;
    --echoTextPrimary: #1D1D1D;
    --echoTextSecondary: #445067;
    --echoPlaceholder: #90AFC6;
    --echoSecondary: #5EAAE1;
    --echoWhite: #FFFFFF;
    --echoStroke: #EEF4F9;
    --echoDisableBG: #FAFCFF;
    --echoRed: #E25353;
    --echoRedTransparency: #FCE7E6;
    --echoGreen: #29B593;
    --echoUndefined: #9A9EC3;

}