.Tree{
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;

    .TreeItem{
        display: flex;
        background: var( --background );
        border-radius: 8px;
        padding: 8px;
        margin-bottom: 12px;
        font-size: 12px;
        color: var(--caption);
        cursor: pointer;
    }
    .TreeItemsItemBlock{
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        padding: 0 12px;
        color: var( --caption ); 
    }
    .TreeItemsItem{
        display: flex;
        background: var( --background );
        border-radius: 8px;
        padding: 8px;
        font-size: 12px;
        margin-left: 12px;
        cursor: pointer;
    }
    .SelectedTree{
        background: var(--item);
        color: var( --white )
    }
    .SelectedTreeItem{
        background: var(--item);
        color: var( --white )
    }
}