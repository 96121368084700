.TagsItem{
    padding: 12px;
    .formRow{
        margin-bottom: 12px;
        .labelText{
            font-size: 10px;
            color: var( --caption);
            padding-bottom: 8px;
            text-transform: uppercase;
        }
    }
    .TagsColors{
        display: flex;
        flex-flow: row wrap;
        padding: 12px 0;
        .tagColorItem{
            width: 28px;
            height: 28px;
            margin-bottom: 12px;
            margin-left: 4px;
            border-radius: 4px;
            cursor: pointer;
        }
        .active{
            outline: 1px solid red;
        }
    }

}