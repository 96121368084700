.AddTag{
    width: calc(502px - 48px);
    .line{
        border-top: 1px solid var( --inputBackground );
    }
    .existingTagsBlock{
        width: 100%;
        max-height: 130px;
        min-height: 32px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        overflow-y: auto;
        .tagBlock{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .checkboxBlock{
                display: flex;
                margin-right: 12px;
            }
            /*svg {
                margin-left: 4px;
                path{
                stroke: var( --blue )
                }
            }*/
        }
        >:not(:first-child){
            margin-top: 12px;
        }
    }
    .createNewTagsBlock{
        .headerTitleText{
            display: flex;
            justify-content: center;
            margin-bottom: 24px;
            span{
                font-size: 14px;
            }
        }
        .TagsTitleText{
            font-size: 10px;
            color: var( --captionText );
        }
        .colorsBlock{
            display: flex;
            flex-flow: row wrap;
            margin-top: 8px;
            .activeColor{
                outline: 3px solid var( --blue );
                outline-offset: -3px;
            }
        }
        .colorBlock{
            width: 44px;
            height: 32px;
            border: 1px solid var(--gray);
            margin-bottom: 12px;
            cursor: pointer;
        }
        .colorBlock:not(:first-child){
            margin-left: 12px;
        }
        .colorBlock:nth-child(8n+9){
            margin-left: 0;
        }
        .newTagPreview{
            display: flex;
            justify-content: flex-start;
            margin: 12px 0;
        }
    }
    .ButtonsBlock{
        display: flex;
        justify-content: center;
        >:not(:first-child){
            margin-left: 12px;
        }
    }
    .tagBlock span.Tag{
        display: flex;
        justify-content: space-between;
        border-radius: 2px;
        padding: 4px;
        line-height: 12px;
        margin-left: 12px;;
        cursor: pointer;
    }
    .rowName{
        margin: 8px 0;
    }

}

@media only screen and (max-device-width: 800px), screen and (max-width: 800px) {
    .AddTag{
        width: 100%;
        .createNewTagsBlock{
            .colorsBlock{
                width: calc(100% - 24px);
            }
            .colorBlock:first-child{
                margin-left: 12px;
            }
            .colorBlock:nth-child(8n+9){
                margin-left: 12px;
            }
        }
    }

}