.ItemsList{

    .ItemsBlock{
        display: flex;
        flex-direction: column;
        position: relative;
        .listBlock{
            width: 100%;
            max-height: 300px;
            display: flex;
            flex-direction: column;
            background: var( --white );
            border-radius: 8px;
            border: 1px solid var( --grey );
            
            overflow-y: auto;
            background: var(--white);
            position: absolute;
            top: 31px;
            *> div{
                width: calc(100% - 8px );
                font-size: 12px;
                padding: 4px;
                cursor: pointer;
                *> div{
                    margin: 0 8px;
                }
            }
            *> div:hover{
                background: var( --purple );
                color: var(--white) 
            }
        }
        .block{
            border: 1px solid var( --grey );
            .itemBlock{
                cursor: pointer;
            }
        }
    }
    .TextBlock{

    }
    .itemBlock{
        display: flex;
    }
    .itemBlock > span{
        font-size: 12px;
    }
    .block{

    }
}