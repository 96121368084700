.PaginatorBlock{
    //width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    ul{
        display: flex;
        li{
            width: 34px;
            height: 34px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            list-style: none;
            font-size: 12px;
            margin-left: 3px;
            padding: 0 3px;
            color: var(--caption);
            cursor: pointer;
            a{
                display: flex;
                align-items: center;
                justify-content: center;
                width: calc(100% - 3px);
                height: 100%;
            }
        }
        li.selectedPage{
            background-color: var(--itemText);
            color: var( --selectedDropdownText )
        }
    }
    .addPageNumberBlock{
        display: flex;
        align-items: center;
        .addPageNumber{
            width: 85px;
            margin-left: 14px;
        }
        .labelText{
            font-size: 10px;
            text-transform: uppercase;
            color: var( --grayText);
        }
    }
    .breakLabel{
        width: 34px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid var( --grayText );
        border-radius: 8px;
        box-shadow: inset 3px 3px 2px 0 rgba(0, 0, 0, 0.1);
    }
    .showRowsCount{
        font-size: 10px;
        color: var( --captionText);
        text-transform: uppercase;
    }
}