.Authorization{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .PageForm{
        width: calc(380px - 48px*2);
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        padding: 24px 48px;
        background: var( --postBackground );
        margin-bottom: 100px;
        .switchButtonRow{
            width: 100%;
            display: flex;
            justify-content: space-around;
            margin-top: 48px;
            span{
                font-size: 16px;
                font-weight: 400;
                line-height: 18.96px;
                color: var( --itemText );
            }
        }
    }
}