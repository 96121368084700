.SettingsMenu{
    width: 100%;
    padding-bottom: 48px;
    .MenuItem{
        width: 100%;
        height: 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: none;
        border-radius: 8px;
        background: var( --background );
        cursor: pointer;
    }
    .MenuItem:disabled{
        cursor: progress;
    }
    .MenuItem:not(:last-child){
        margin-bottom: 16px;
    }
    span.itemName{
        margin-left: 12px;
        font-size: 12px;
        color: var( --caption );
    }
    .status{
        margin-right: 12px;
    }
    .activeMenu{
        background: var( --item );
        span{
            color: var( --white )
        }
    }
}