.List{
    .ListBlock{
        .ListHeader{
            margin-bottom: 12px;
        }
        .ListBody{
            min-height: 22px;
            max-height: 196px;
            overflow-y: auto;
            padding: 4px 8px;
            background: var( --background );
            border: 1px solid var( --gray );
            border-radius: 8px;
            margin-bottom: 10px;
            .listItem{
                display: flex;
                margin-bottom: 4px;
                span{
                    margin-left: 4px;
                    font-size: 12px;
                }
            }
        }

    }
    .list{
        border: none;
        background: none;
    }
    .attached{
        max-height: 100px;
        border: 1px solid var( --grey );
        border-radius: 8px;
        padding: 8px;
        margin: 12px 0;
        overflow-y: auto;
        span{
            font-size: 12px;
        }
    }
    .formRow{
        display: flex;
        margin: 12px 0;
        span{
            font-size: 12px;
            color: var(--echoHeader);
            margin-right: 8px;
        }
    }
}