.formBody{
    width: 100%;
    margin-top: 24px;
    .labelRow{
        .labelText{
            font-size: 10px;
            color: var( --gray );
            text-transform: uppercase;
            margin-bottom: 8px;
        }
    }
    .formMessage{
        width: calc(100% - 80px);
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 14px;
        line-height: 17px;
        color: var( --darkPurple );
        margin: 0 40px;
        text-align: center;
        margin-top: 24px;
        a{
            color: var( --blue );
            text-decoration: none;
        }
        button{
            margin-top: 24px;
        }
    }
}