.TelegramBots{
    .titleText{
        font-size: 12px;
        margin-right: 12px;
    }
    .itemRow{
        display: flex;
        align-items: center;
        justify-content: center;
        span{
            color: var( --caption );
        }
        .SubscriberText{
            text-align: center;
            font-size: 12px;
            color: var( --caption );
            text-transform: uppercase;
        }
        .Subscribers{
            display: flex;
            flex-direction: column;
            margin: 12px 0;
            .subscriberItemBlock{
                display: flex;
                flex-direction: column;
                .subscriberItem{
                    display: flex;
                    align-items: center;
                    padding: 4px 12px;
                    height: calc(32px - 4px);
                    background: var(--white);
                    border: 1px solid var( --gray);
                    border-radius: 8px;
                    font-size: 12px;
                    cursor: pointer;
                }
                .itemCol{
                    display: flex;
                    flex-direction: column;
                    
                }
                .itemCol:first-child{
                    align-items: flex-start;
                }
                .itemCol:last-child{
                    align-items: flex-end;
                }
                .itemColName{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    margin-right: 12px;
                }
                .labelText{
                    font-size: 12px;
                    color: var( --captionText);
                    padding: 8px;
                    text-transform: uppercase;
                }
                .subscriberRow{
                    margin: 8px 0;
                }
                .subscribers{
                    max-height: 110px;
                    display: flex;
                    flex-flow: row wrap;
                    overflow-y: auto;
                    
                }
            }
        }
    }
}