.Filters{
    margin: 24px 0;
    .filtersItemsBlock{
        border-radius: 8px;
        background: var(--background);
        .filterRightBlock{
            display: flex;
            >:first-child{
                margin-right: 12px;
            }
        }
    }
    .filtersItem{
        width: 100%;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 11px;
        padding: 0 12px;
        color: var(--caption);
        background: none;
        border: none;
        cursor: pointer;
    }
    .filtersItemsBlock:not(:first-child){
        margin-top: 18px;
    }
    .buttonsBlock{
        width: 100%;
        display: flex;
        justify-content: space-around;
    }
    div[fixed='true'] > div{
        position: fixed;
        top: 163px;
        right: 10px;
        width: 291px;
        background: white;
        padding: 12px;
        box-shadow: 0px 8px 2px -2px #00000055;
    }
}