.Preload{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 32px;
}
.Loader {
    height: 80px;
    width: 80px;
    animation: loader 1.2s linear infinite;

}
.Loader::before {
    content: " ";
    display: block;
    width: 100%;
    height: 100%;
    //margin: 8px;
    border-radius: 50%;
    outline: 6px solid var(--blue);
    border-color: var( --blue );


}
.Loader::after{
    content: ' ';
    display: block;
    width: 12px;
    height: 12px;
    position: absolute;
    border-radius: 50%;
    top: 20px;
    right: 20px;
    background: var( --blue );
    z-index: 100;
}
@keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .SpinnerBlock{
    display: flex;
    justify-content: center;
  }
.Spinner{

}

.Spinner {
  /* change color here */

  color: var( --echoSecondary )
}
.Spinner,
.Spinner div {
  box-sizing: border-box;
}
.Spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.Spinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 120px;
  height: 120px;
  margin: 8px;
  border: 8px solid currentColor;
  border-radius: 50%;
  animation: Spinner 1.3s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: currentColor transparent transparent transparent;
}
.Spinner div:nth-child(1) {
  animation-delay: -0.45s;
}
.Spinner div:nth-child(2) {
  animation-delay: -0.3s;
}
.Spinner div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes Spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Bar {
  width: 36.4px;
  height: 15.8px;
  --c: linear-gradient(var(--echoWhite) 0 0);
  background: var(--c) 0% 100%, var(--c) 50% 100%, var(--c) 100% 100%;
  background-size: 10.1px 100%;
  background-repeat: no-repeat;
  animation: Bar-mbi2jdmd 1s infinite linear;
}

@keyframes Bar-mbi2jdmd {
  20% {
     background-size: 10.1px 60%, 10.1px 100%, 10.1px 100%;
  }

  40% {
     background-size: 10.1px 80%, 10.1px 60%, 10.1px 100%;
  }

  60% {
     background-size: 10.1px 100%, 10.1px 80%, 10.1px 60%;
  }

  80% {
     background-size: 10.1px 100%, 10.1px 100%, 10.1px 80%;
  }
}