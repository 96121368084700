.Grouping{
    margin-top: 12px;
    span{
        font-size: 12px;
    }
    .GroupingButtons{
        display: flex;
        margin-bottom: 12px;
        >*:not(:first-child){
            margin-left: 4px;
        }
    }
    .labelText{
        font-size: 12px;
        color: var( --captionText);
        padding-bottom: 8px;
        text-transform: uppercase;
    }
}