.MigrationItem{
    padding: 24px 0;
    .migrationRow{
        display: flex;
        align-items: center;
        justify-content: space-between;

    }
    .DateBlock > div{
        margin-bottom: 8px;
    }
}