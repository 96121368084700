.headerBlock{
    width: 100%;
    //flex-direction: column;
    background: var(--header);
    position: fixed;
    top: 0;
    z-index: 5;
    .block{
        display: flex;
        justify-content: space-between;
    }
    .headerTop{
        width: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px;
        .LogoButtonBlock{
            display: flex;
            justify-content: space-between;
            .MenuButton{
                cursor: pointer;
            }
        }
        .LogoBlock{
            display: flex;
            align-items: center;
            margin-right: 40px;
            width: 200px;
            cursor: pointer;
            svg{
                path:nth-child(1), path:nth-child(2), path:nth-child(3), polygon:nth-child(4){
                    fill: white
                }
            }
            .logoText{
                display: flex;
                flex-direction: column;
                margin-left: 6px;
                span{
                    color: var( --headerCaption );
                    white-space: nowrap;
                    font-size: 15px;
                    color: 0,32,76;
                    line-height: 1;
                    font-weight: normal;
                }
                span:first-child{
                    font-weight: bolder;
                }
            }

        }
    }
    .headerTop:first-child{
        //padding-bottom: 0;
        border-bottom: 1px solid #6E798F;
    }
    .rightMenu{
        padding: 12px;
    }
    .topHeaderButtons{
        width: auto;
        display: flex;
        justify-content: space-between;
        .Notifications{
            display: flex;
        }
    }
    .headerMedium{
        display: flex;
        align-items: center;
    }
}

/*@media only screen and (max-device-width: 1000px), screen and (max-width: 1000px) {
    .headerBlock{
        .block{
            display: flex;
            flex-direction: column;
            width: 100%;
        }
        .headerTop{
            flex-direction: column;
        }
    }
    .topHeaderButtons{
        flex-direction: column;
        width: 100% !important;
    }
}*/