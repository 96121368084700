.TelegramChat{
    .subscriptionRow{
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        >:not(:first-child){
            margin-left: 8px;
        }
        span{
            font-size: 12px;
        }
    }
}