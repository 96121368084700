.MobileHeader{
    width: 100%;

    //flex-direction: column;
    background: var(--header);
    position: fixed;
    top: 0;
    z-index: 25;
    .LogoBlock{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 12px;
        svg{
            path:nth-child(1), path:nth-child(2), path:nth-child(3), polygon:nth-child(4){
                fill: white
            }
        }
    }
    .languageBlok{
        display: flex;
        margin-right: 12px;
        >*{
            margin-left: 12px;
        }
    }
}