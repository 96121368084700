.MyFilters{
    display: flex;
    margin-bottom: 12px;
    >*:not(:first-child){
        margin-left: 8px;
    }
    button{
        svg{
            path{
                fill: var( --caption );
            }
        }
    }
}