.CreateCompanyAccount{
    .formRow{
        margin: 8px 0;
        .labelText{
            font-size: 10px;
            color: var( --captionText);
            padding-bottom: 8px;
            text-transform: uppercase;
        }
    }
    .end{
        display: flex;
        justify-content: flex-end;
    }
    .accountBlock{
        flex-direction: column;
    }
    .warningText{
        font-size: 12px;
        color: var( --captionText )
    }
}