.inputBlock, .textareaBlock{
    //display: flex;
    //flex-direction: column;
    .inputWrapper, .ReadOnlyBlock{
        display: flex;
        width: calc(100% - 16px);
        max-height: calc(32px - 16px);
        min-height:  calc(32px - 16px);
        background: var( --white );
        border-radius: 8px;
        border: 1px solid var( --gray);
        padding: 7px;
        position: relative;
        cursor: default;
        .showPasswordCheckbox{
            position: absolute;
            right: 0;
            top: 0;
            label{
                width: 32px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                .showPassword circle{
                    fill: var( --black );
                }
            }
            input[type=checkbox]{
                display: none;
            }
        }

    }
    .bordered{
        border: 1px solid var( --gray ) !important;
        padding: 5px;
    }
    .readOnly{
        border: none;
    }
    span{
        font-size: 10px;
        font-weight: 400;
        font-style: normal;
        line-height: 11.85px;
        color: var( --gray );
        text-transform: uppercase;
    }
    input, textarea{
        width: 100%;
        height: 100%;
        background: var( --white );
        display: flex;
        border: none;
        padding: 0;
        font-size: 12px;
        background-color: var(--white) !important;
    }
    input:focus, input:active, textarea:focus, textarea:active{
        outline: none;
    }
    input::placeholder, textarea::placeholder{
        font-size: 10px;
        font-weight: 400;
        text-transform: uppercase;
    }
    input:read-only{
        cursor: default;
    }
    textarea{
        //height: calc(100px - 22px);
    }
    .notResized{
        textarea{
            resize: none;
            white-space: nowrap;
        }
        textarea::-webkit-scrollbar {
            display: none;
          }
        textarea:focus, textarea:active{
            outline: none;
        }
        
    }
    .TimeInput{
        input{
            width: 50%;
        }
        
    }
}
.textareaBlock{
    .inputWrapper{
        height: calc(100px - 22px);
        min-height: calc(100px - 22px);
    }
}
.inputError{
    position: relative;
    .inputWrapper{
        border: 1px solid var( --red );
    }
    .formSummaryError{
        position: absolute;
        top: 38px;
        color: var( --red )
    }
    input{
        
    }
}
.inputWrapper{

}
.textareaBlock.inputError{
    .formSummaryError{
        top: 104px;
    }
}
.NumberInput{
    button{
        padding: 0;
        svg{
            margin: 0 4px;
        }
    }
}

.errorMessage{
    position: absolute;
    top:35px;
    font-size: 10px;
    color: var( --red )
}
.SelectDropdown{
    width: 100%;;
    height: 32px;
    display: flex;
    align-items: center;
    background: var( --white );
    border-radius: 8px;
    border: 1px solid var( --gray);
    position: relative;
    cursor: pointer;
    .DropdownPlaceholder{
        font-size: 10px;
        text-transform: uppercase;
        color: var( --captionText )
    }
}
.SelectOption{
    width: 100%;
    position: absolute;
    top: calc(100% + 4px);
    background: var( --backgroundLight );
    box-shadow: 0px 0px 15px #00000055;
    border-radius: 8px;
    overflow-y: auto;
    z-index: 1;
}
.Option{
    width: calc(100% - 8px);
    height: 32px;
    display: flex;
    align-items: center;
    padding-left: 8px;
    font-size: 10px;
    color: var( --darkPurple );
    span{
        /*text-transform: uppercase;*/
    }
}
.SelectedText{
    font-size: 10px;
    color: var( --darkPurple );
    //text-transform: uppercase;
}
.SelectRow{
    width: calc(100% - 16px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
}
.ButtonsBlock{
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    button{
        width: 20px;
        height: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: none;
        border: none;
        cursor: pointer;
    }
}
.LabelText{
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    span{
        font-size: 10px;
        color: var( --captionText)
    }
}
.TimeBlockWrapper, .DateBlockWrapper{
    .TimeBlock{
        display: flex;
        align-items: center;
        position: relative;
        input{
            width: 31px;
            height: calc(32px - 6px);
            max-height: 32px;
            border: 1px solid var( --gray );
            border-radius: 8px;
            color: var( --darkPurple);
            padding: 0 5px;
        }
        input:nth-child(5){
            width: 47px;
        }
        input:focus, input:active, textarea:focus, textarea:active{
            outline: none;
        }
        .separator{
            width: 12px;
            text-align: center;
        }

    }
}
