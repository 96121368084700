.MultiPostPanel{
    width: calc( 100% - 24px);
    height: 32px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    .buttonsPanel{
        display: flex;
        align-items: center;
        >*{
            margin-right: 12px;
        }
    }
    .labelText{
        font-size: 10px;
        color: var( --captionText )
    }
    .multiItem{
        display: flex;
        align-items: center;
        > span{
            margin-right: 8px;
        }
        .sentiments{
            display: flex;
            background: var( --background );
            border-radius: 8px;
            padding: 4px 8px;
            >:not(:first-child){
                margin-left: 4px
            }
        }
        .multiItem{
            position: relative;
        }
    }
    .multiItem:not(:first-child){
        margin-left: 24px;
    }
    .IconAttribute{
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var( --white);
        border-radius: 4px;
        cursor: pointer;
        svg{
            width: 17px;
            height: 17px;
            margin: 0 !important;
        }
    }
    .IconAttribute:not(:first-child){
        margin-left: 4px;
    }
    .red{
        svg > path{
            fill: red
        }
    }
    .grey{
        svg > path{
            fill: var( --grey );
        }
    }
    .purple{
        svg > path{
            fill: var( --purple );
        }
    }
    .green{
        svg > path{
            fill: var(--green);
        }
    }
    .Tag{
        border-radius: 2px;
        padding: 4px 8px;
        line-height: 12px;
        font-size: 12px;
    }
    .tagsBlock{
        width: calc(250px - 16px);
        max-height: 150px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 8px;
        background: var( --background );
        position: absolute;
        right: 0;
        z-index: 15;
        box-shadow: 0px 0px 15px #00000055;
        padding: 8px;
        overflow-y: auto;
        span{
            width: auto;
            display: flex;
        }
        span:not(:first-child){
            margin-top: 8px;
        }
    }
}
@media only screen and (max-device-width: 800px), screen and (max-width: 800px) {
    .MultiPostPanel{
        height: 52px;
    }
}

