.Authorities{
    
    .AuthoritiesItems{
        height: 250px;
        display: flex;
        flex-direction: column;
        border: 1px solid var( --gray);
        border-radius: 8px;
        padding: 8px;
        overflow-y: auto;
    }
    .AuthorityItem{
        display: flex;
        margin: 4px 0;
        cursor: pointer;
    }
    .authorityName{
        font-size: 12px;
        color: var( --caption );
        margin-left: 4px;
    }
    .labelText{
        font-size: 12px;
        color: var( --captionText);
        padding-bottom: 8px;
        text-transform: uppercase;
    }
}