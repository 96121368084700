.AnalyticItem{
    height: calc(160px - 12px);
    width: calc(300px - 24px);      
    background: var( --chartBackground );
    border-radius: 8px;
    padding: 12px 12px 0 12px;
    margin-right: 12px;
    .topicTop{
        height: 84px;
        .topicName{
            font-size: 14px;
            font-weight: 400;
            color: var( --blue );
            cursor: pointer;
        }

    }
    .topicBottom{
        height: 64px;
        display: flex;
        border-top: 1px solid var( --white );
        .TopicDates{
            width: 50%;
            height: calc(100% - 12px);
            padding-top: 12px;
            border-right: 1px solid var( --white );
            div{
                display: flex;
                align-items: center;
                margin-bottom: 4px;
                span{
                    font-size: 10px;
                    margin-left: 6px;
                    line-height: 12px;
                    color: var( --captionText)
                }
                svg{
                    width: 10px;
                    path[data-path="fill"]{
                        fill: var( --caption );
                        //fill: var( --caption );
                    }
                }
            }

        }
        .NewMessages{
            width: 50%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .NewMessagaesCount{
                font-size: 24px;
                font-weight: 400;
                color: var( --caption );
            }
            .NewMessagaesText{
                font-size: 10px;
                text-transform: uppercase;
                color: var( --captionText)
            }
        }
    }

}
@media (max-width: 1500px) {
    .AnalyticItem{
        height: calc(160px - 12px);
        width: calc(200px - 24px);
    }
}

@media only screen and (max-device-width: 1400px), screen and (max-width: 1400px) {
    .AnalyticItem{
        height: calc(160px - 12px);
        width: calc(224px - 24px);
        .topicBottom{
            .TopicDates{
                width: 95%;
            }
        }

    }
}

@media only screen and (max-device-width: 1300px), screen and (max-width: 1300px) {
    .AnalyticItem{
        width: 90%;
        .topicBottom{
            .TopicDates{
                width: 95%;
            }
            .NewMessages{
                display: none;
            }
        }

    }
}

