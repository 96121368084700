.CompanyTopicItem{
    display: flex;
    flex-direction: column;
    width: calc(100% - 24px);
    height: 100%;
    background: var( --background );
    padding: 12px;
    border-radius: 8px;
    .keywords{
        width: calc(100% - 16px);
        display: flex;
        flex-direction: column;
        background: var( --white );
        font-size: 12px;
        color: var( --darkPurple);
        //margin: 12px 0;
        padding: 8px;
        border-radius: 8px;
    }
    .labelText{
        font-size: 12px;
        color: var( --captionText);
        padding-bottom: 8px;
        text-transform: uppercase;
    }
}