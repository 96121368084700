.AdminMarkerAddItem{
    padding: 12px 8px;
    .attachedTopics{
        max-height: 100px;
        border: 1px solid var( --grey );
        border-radius: 8px;
        padding: 8px;
        margin: 12px 0;
        overflow-y: auto;
        span{
            font-size: 12px;
        }
    }
    .formRow{
        margin: 12px 0;
        span{
            font-size: 12px;
            color: var(--echoHeader);
        }
    }
}