.SelectBlock{
    display: flex;
    justify-content: flex-end;
    position: relative;
    .ListOfFormats{
        width: 40px;
        display: flex;
        flex-direction: column;
        padding: 8px 0;
        background: var( --white );
        border-radius: 8px;
        position: absolute;
        top: 30px;
        z-index: 1;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.333333333);
        button{
            border: none;
            background: var( --white );
            cursor: pointer;
        }
        button:hover{
            background: var( --selectedButton);
            color: var( --selectedButtonText);
        }
    }
    .maxSize{
        display: flex;
        align-items: center;
        margin: 0 12px;
        > span{
            font-size: 12px;
        }
    }
}
