.Badge{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var( --item );
    color: var( --white);
    padding: 2px 8px;
    border-radius: 10px;
    font-size: 11px;
    white-space: nowrap;
}