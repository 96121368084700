.ModalBlock{
    margin-top: 24px;
    .headerText{
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
    }
    .BodyText{
        color: var( --caption );
    }
    .buttonsRow{
        width: calc(100% - 24px);
        display: flex;
        justify-content: center;
        margin: 12px;
        button:not(:first-child){
            margin-left: 24px;
        }
    }
}