.ModalWindow{
    width: 100%;
    height: 100%;;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 50;
    .modalWindow{
        max-width: 80%;
        height: auto;
        border-radius: 8px; 
        background: var( --postBackground );
        padding: 20px;
        .modalHeader{
            position: relative;
            height: 34px;
            .draggableZone{
                width: calc(100%);
                height: 16px;
                position: absolute;
                top: -20px;
                left: -20px;
                display: flex;
                justify-content: space-between;
                padding: 20px;
                border-radius: 8px 8px 0 0;
                div{
                    width: 24px;
                    height: 24px;
                }
                span {
                    font-size: 14px;
                    font-weight: 500;
                    color: var(--caption );
                }
                button{
                    width: 24px;
                    height: 24px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: none;
                    border: none;
                    cursor: pointer;
                    font-size: 28px;
                }
                button:after{
                    display: inline-block;
                    content: "\00d7";
                    color: var( --caption );
                  }
                
            }
            .draggableZone:hover{
                background: var( --selectedModalItem );
                cursor: grab;
                svg{
                    path{
                        fill: 'white'
                    }
                }
            }
            .close{
                width: 30px;
                height: 30px;

            }
            .closeButton{

            }
        }

        .modalBody{
            max-height: 500px;
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            overflow-x: hidden;
            font-size: 12px;
            .modalButtons{
                button{
                    background: none;
                    border: none;
                    cursor: pointer;
                }
            }
        }

    }
}
