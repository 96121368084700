.SaveFiltersItems{
    width: 502px;
    margin-top: 12px;
    .buttonsBlock{
        display: flex;
        justify-content: center;
        margin-top: 12px;
        >*:not(:first-child){
            margin-left: 12px;
        }
    }
    .Message{
        margin: 18px 0 30px 0;
        font-size: 10px;
        color: var(--caption);
        text-transform: uppercase;
    }
}