@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Rubik'), local('./Rubik-VariableFont_wght.ttf'), url('./Rubik-VariableFont_wght.ttf') format('truetype');
}


@font-face {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
  src: local('Rubik'), local('./Rubik-ExtraBold.ttf'), url('./Rubik-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  src: local('Rubik'), local('./Rubik-Regular.ttf'), url('./Rubik-Regular.ttf') format('truetype');
}

@font-face {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  src: local('Rubik'), local('./Rubik-Medium.ttf'), url('./Rubik-Medium.ttf') format('truetype');
}

@font-face {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  src: local('Rubik'), local('./Rubik-SemiBold.ttf'), url('./Rubik-SemiBold.ttf') format('truetype');
}