.ErrorMessage{
    width: 80%;
    background: var(--echoRedTransparency);
    display: flex;
    border-radius: 8px;
    position: absolute;
    top: -70px;
    padding: 12px;
    .ErrorMessageBlock{
        display: flex;
        flex-direction: column;
        .errorLabel{
            margin-bottom: 17px;
            span{
                font-size: 16px;
                font-weight: 800;
            }
            
        }
        span{
            margin-left: 12px;
            font-size: 12px;
            
        }
    }
    span{
        font-size: 10px;
        color: var( --echoRed )
    }
}

.ErrorMessageLabel{
    position: relative;
    display: flex;
    margin-right: 4px;
    .MessageBlock{
        display: flex;
        background: var( --echoRed );
        position: absolute;
        top: -26px;
        left: -7.5px;
        border-radius: 4px;
        span{
            font-size: 10px;
            color: var( --echoWhite );
            padding: 4px 8px;
            white-space: nowrap;
            text-transform: none;
        }
    }
    .MessageBlock:after, .MessageBlock:before {
        content: ""; 
        border: solid transparent;
        position: absolute; 
        top: 100%;
        left: 10px;
    }
    .MessageBlock:before {
        border-top-color: var( --echoRed);
        border-width: 5px;
        margin-left: -1px;
    }
}