.Analytics{
    display: flex;
    //flex-direction: column;
    flex-flow: row wrap;
    .AnalyticChart{
        background: var( --background );
        border-radius: 8px;
        padding: 12px;
        margin-left: 12px;
    }
    .AnalyticChart:not(:first-child){
        margin-top: 24px;
    }
}