.Admin{
    width: calc(100% - 24px);
    display: flex;
    flex-flow: column;
    margin: 12px;
    .titleRow{
        display: flex;
        justify-content: center;
        margin: 24px 0;
    }
    .buttonsBlock{
        width: 335px;
        display: flex;
        justify-content: space-between;
    }
    .companiesList{
        width: 100%;
    }
    .tableBlock{
        display: flex;
        flex-direction: column;
        padding: 12px;
        margin: 12px;
        border: 1px solid var( --darkPurple );
        border-radius: 8px;
        > span{
            font-size: 14px;
            color: var( --darkPurple);
            margin-bottom: 12px;
        }
    }
}