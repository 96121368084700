.TelegramItem{
    width: calc(100% - 24px);
    background: var( --background );
    padding: 12px;
    border-radius: 8px;
    .ItemRow{
        margin: 12px 0;
    }
    .Title{
        font-size: 12px;
        color: var( --caption)
    }
    .buttonsBlock{
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    .PolicyItemsBlock{
        max-height: 350px;
        overflow-y: auto;
    }
    .PolicyItems{
        display: flex;
        flex-direction: column;
        border: 1px solid var( --gray );
        padding: 12px;
        margin: 12px 0;
        > span{
            margin-bottom: 12px;
        }
        box-shadow: 0px 0px 15px #00000055;
            border-radius: 8px;
        >*{
            margin-bottom: 12px;
        }
        .policyItem{
            //padding: 12px;
            .policyStartTimeBlock{
                
                margin-top: 12px;
                .policyStartTime{
                    display: flex;
                    align-items: flex-end;
                    .policyStartDate{
                        width: 166px;
                    }
                    button{
                        margin-left: 12px;
                    }
                }
            }

        }
    }
    .titleText{
        font-size: 12px;
        margin-right: 12px;
    }
    .itemRow{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .labelText{
        font-size: 10px;
        color: var( --captionText);
        padding-bottom: 8px;
        text-transform: uppercase;
    }
}