.AccountCabinet{
    
    min-height: 350px;
    .AccountContainer{
        width: 300px;
        padding: 24px;
        .block{
            position: relative;
            input{
                cursor: copy;
            }
        }
        .rowBetween{
            display: flex;
            justify-content: space-between;
        }
        .icon{
            position: absolute;
            top: 25px;
            right: 12px;
            
        }
    }
}