.Signup{
    width: 100%;
    .labelRow{
        .labelText{
            font-size: 10px;
            color: var( --gray );
            text-transform: uppercase;
            margin-bottom: 8px;
        }
    }
    .halfRow{
        width: 50%;
        display: flex;
        justify-content: space-between;
    }
    h3{
        font-size: 14px;
        color: var( --darkPurple )
    }
    h4{
        font-size: 10px;
        color: var( --darkPurple )
    }
    .errorMessage{
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 10px;
        position: relative;
        span{
            position: absolute;
            top: -24px;
            color: var( --red );
        }
    }
}