.CreateCompany{
    display: flex;
    flex-direction: column;
    padding: 24px 0;
    .formRow{
        padding: 6px 0;
        .DateBlock{
            >*{
                margin: 8px 0;
            }
                
            
        }
        .labelText{
            font-size: 12px;
            color: var( --captionText);
            padding-bottom: 8px;
            text-transform: uppercase;
        }
    }
}