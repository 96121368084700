.EditUser{
    width: 100%;
    background: var( --background );
    border-radius: 8px;
    padding: 12px;
    .labelRow{
        margin-bottom: 18px;
        .labelText{
            font-size: 10px;
            color: var( --gray );
            text-transform: uppercase;
            margin-bottom: 8px;
        }
    }
}