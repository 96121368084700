.checkboxBlock{
    width: 16px;
    height: 16px;
    display: flex;
    label{
        cursor: pointer;
        width: 100%;
        height: 100%;
        background: var( --backgroundColor );
    }

    label[datatype=disabled]{
        cursor: auto;
        svg{
            path{
                fill: var( --backgroundLight )
            } 
        }
    }
    input[type=checkbox]{
        position: absolute;
        display: none;
    }
    input[type=checkbox]:disabled{
        color: red
    }
    button{
        width: auto;
        height: auto;
        background: none;
        border: none;
        padding: 0;
    }
}
.checkboxBlock[data-checked='checked']{
    label{
        svg{
            rect{
                fill: var( --item );
            }
        }
    }
}