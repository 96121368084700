button.HeaderButton{
    height: 32px;
    display: flex;
    align-items: center;
    background: none;
    font-size: 12px;
    border: none;
    border-radius: 8px;
    color: black;
    padding: 0;
    span, a{
        display: flex;
        align-items: center; 
    }
    a{
        height: 100%;
        width: calc(100% - 18px);
        text-decoration: none;
        color: var( --headerCaption );
        padding: 0 9px;
        svg{
            path[data-path="fill"]{
                fill: var( --headerCaption )
            }
            path[data-path="stroke"]{
                stroke: var( --headerCaption )
            }
            circle[data-circle="fill"]{
                fill: var( --headerCaption )
            }
        }
    }
    cursor: pointer;
    svg{
        margin-right: 6px;
    }
}
button.HeaderButton:not(:first-child){
    margin-left: 12px;
}
button.HeaderButton:hover{
    background: var( --selectedButton );
    svg{
        path[data-path='fill']{
            fill: var( --selectedButtonText )
        }
        path[data-path='stroke']{
            stroke: var( --selectedButtonText )
        }
        circle[data-circle='fill']{
            fill: var( --selectedButtonText )
        }
    }
    span{
        color: var( --selectedButtonText );
    }
}
a.active{
    background: var(--selectedButton)
}

/*@media only screen and (max-device-width: 1000px), screen and (max-width: 1000px) {
    button.HeaderButton{
        width: 100%;
        border-radius: 0;
        border-bottom: 1px solid var( --grey);
    }
    button.HeaderButton:not(:first-child){
        margin-left: 0;
    }
}*/