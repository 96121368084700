.PanelListOfPublications{
    width: calc(100% - 16px);
    height: 32px;
    display: flex;
    justify-content: space-between;
    padding: 8px;
    background: var( --panelList );
    position: relative;
    .panel{
        //width: 50%;
        display: flex;
        align-items: center;
        >button:first-child {
            margin-right: 12px;
        }
        .labelText{
            font-size: 10px;
            color: var(--captionText);
            text-transform: uppercase;
            margin-left: 8px;
        }
        button{
            svg{
                path[data-path="fill"]{
                    fill: var( --white ); 
                }
            }
        }
    }
    .centralBlock{
        display: flex;
        align-items: center;
    }
    .mobileCentralBlock{
        position: absolute;
        top: 100%;
    }
    .postsCount{
        display: flex;
        align-items: center;
        font-size: 10px;
        color: var(--captionText);
        text-transform: uppercase;
        margin-left: 12px;
        >span{
            font-size: 13px;
            margin-left: 8px;
            color: var( --caption )
        }
    }
}

@media only screen and (max-device-width: 1000px), screen and (max-width: 1000px) {
    .PanelListOfPublications{
       // width: 100%;
    }
}