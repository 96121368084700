.DigestWeekDays{
    padding-top: 12px;
    .headerBlock{
        border-bottom: 1px solid var( --gray);
        padding: 4px 0;
    }
    .bodyBlock{
        max-height: 100px;
        display: flex;
        flex-flow: column wrap;
        padding-top: 12px;
        .checkItem{
            width: 100px;
            margin-right: 12px;
            margin-bottom: 8px;
        }
    }
    .checkItem{
        display: flex;
        span{
            margin-left: 6px;
            font-size: 12px;
            color: var( --darkPurple)
        }
    }
}