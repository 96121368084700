.standartButton{
    width: auto;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    background: var( --item );
    border: none;
    border-radius: 8px;
    color: var( --white );
    font-family: 'Rubik';
    cursor: pointer;

    svg, img{
        margin-right: 7px;
    }
    a{
        color: white;
    }
    path{
        //stroke: white;
    }
}
.standartButton:disabled{
    background: var( --white );
    color: var( --gray );
    cursor: auto;
    svg{
        path{
            stroke: var( --gray )
        }
    }
}
.standartButton.short{
    padding: 7px;
    svg{
        margin: 0;
    }
}
.standartButton{
    svg{
        path[data-path="stroke"]{
            stroke: var( --caption );
        }
        path[data-path="fill"]{
            fill: var( --caption );
        }
    }
    .whiteFill{
        
            path[data-path="fill"], circle{
                fill: var( --white );
            }
        
    }
    .whiteStroke{
            path[data-path="stroke"], circle, rect{
                stroke: var( --white );
            }
    }
    .darkStroke{
        path, circle{
            stroke: var( --darkPurple);
        }
    }
}
.standartButton.white{
    background: var(--white);
    color: var(--darkPurple)
}
.standartButton.controlButton{
    background: var(--controlButton);
    color: var(--caption);
    svg{
        path[data-path="stroke"]{
            stroke: var( --caption );
        }
        path[data-path="fill"]{
            fill: var( --caption );
        }
        rect[data-rect="stroke"]{
            stroke: var( --caption );
        }
        circle[data-circle="fill"]{
            fill: var( --caption );
        }
        circle[data-circle="stroke"]{
            stroke: var( --caption );
        }
    }
}
.backgroundColor{
    background: var( --background );
    path{
        fill: var( --caption );
    }
}
.background{
    background: var( --background );
}