.Datepicker{
    .CalendarsBlock{
        width: calc(100% - 24px);
        display: flex;
        >div:first-child{
            margin-right: 24px;
        }
    }
    .week{
        display: flex;
        .day{
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            color: var( --caption );
        }
        .withDay{
            cursor: pointer;
        }
        .withDay:hover{
            background: var( --selectedButton );
            color: var( --white );
        }
        .disabledDay{
            color: var(--lightGray)
        }
        .disabledDay:hover{
            background: none;
            color: var(--captionText);
            cursor: default;
        }
        .selectedStartDay{
            background: var(--selectedButton);
            color: var( --white );
            border-radius: 8px 0px 0px 8px !important;
        }
        .selectedEndDay{
            background: var(--selectedButton);
            color: var( --white );
            border-radius: 0px 8px 8px 0px !important;
        }
        .intermediate{
            border-radius: 0 !important;
            color: var( --white );
            background: var(--range);
        }
    }
    .DatePickerHeader{
        width: calc(100% - 2px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        .MonthDateBlock{
            width: 180px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            span{
                color: var(--caption)
            }
        }
    }
    .WeekDaysBlock{
        display: flex;
        align-items: center;
        >div{
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            color: var( --captionText );
        }
    }
    .DaysBlock{
        margin-top: 12px;
        padding: 12px;
        background: var(--backgroundColor);
        border-radius: 8px;
    }
    .selectedDay{
        background: var(--selectedButton);
        color: var( --white );
        border-radius: 8px;
    }


    .TopHeader{
        display: flex;
        justify-content: center;
        margin: 12px 0;
        span{
            font-size: 14px;
            color: var( --caption )
        }
    }
    .showDate{
        display: flex;
        margin-top: 24px;
        .DateBlock{
            width: 90px;
            margin-right: 12px;
        }
        .TimeBlock{
            width: 100px;
        }
    }
    .DatepickerButtonsBlock{
        display: flex;
        justify-content: center;
        margin-top: 24px;
        >:last-child{
            margin-left: 12px;

        }
    }
}