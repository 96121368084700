.Advanced{
    //width: calc( 100% - 12px );
    //background: var( --white );
    //padding: 12px;
    border-radius: 8px;
    .AdvancedType{
        display: flex;
        flex-direction: column;
        width: calc( 100% - 12px );
        border: 1px solid var( --captionText );
        border-radius: 8px;
        padding: 12px;
        margin-bottom: 12px;
        .AdvancedItemBlock{
            
            background: var( --panelList );
            padding: 12px;
            border-radius: 8px;
            margin-bottom: 12px;
            //border-bottom: 1px solid var(--gray);
        }
    }
    .AdvancedItem{

        .HeaderBlock{
    
    
            border-bottom: 1px solid var( --gray );
            margin-bottom: 6px;
    
        }
        .ItemsBlock{
            max-height: 195px;
            overflow-y: auto;
            > div{
                width: 100%;
                display: flex;
                flex-flow: row wrap;
                margin-bottom: 6px;
            }
            span{
                font-size: 12px;
                margin-left: 8px;
                color: var( --caption );
            }
        }
    
    }
    .item{
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        margin-bottom: 6px;
        
    }
    .TitleBlock{
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        color: var( --caption );
        padding: 8px 0;
        .toggleButton{
            cursor: pointer;
        }
    }
    .SocialRegions{
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .formRow{
        display: flex;
        margin-bottom: 12px;
        span{
            font-size: 12px;
            white-space: nowrap;
            
        }
        > span{
            margin-left: 8px;
            color: var( --caption );
        }
        > button{
            margin-left: 8px;
        }
    }
    .SocialDomains{
        display: flex;
        align-items: center;
        span{
            font-size: 12px;
            margin-right: 4px
        }

    }
    .SocialDomains{
        max-height: 100px;
        overflow-y: auto;
        table{
            width: 100%;
            //background: var( --background);
    
            td{
                color: var(--caption);
                //border: 1px solid red;
                button{
                        width: 16px;
                        height: 16px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: var(--backgroundLight);
                        border: none;
                        border-radius: 3px;
                        padding: 4px;
                        cursor: pointer;
                    
                }
            }
            th, td{
                font-size: 10px;
                background: var(--white);
                padding: 4px 8px;
            }
            th:first-child, td:first-child, th:last-child, td:last-child{
                min-width: 16px;
                width: 16px;
                max-width: 16px;
            }
            th:nth-child(2), td:nth-child(2){
                width: 100%
            }
        }
    }

}