.MobileMenu{
    width: calc(100% - 60px);
    max-width: 400px;
    display: flex;
    flex-direction: column;
    background: var( --header);
    position: absolute;
    top: 44px;
    right: 20px;
    padding: 12px;
    border-radius: 0px 0px 8px 8px;
    z-index: 20;
    >*{
        margin-bottom: 4px;
    }
    button {
        width: 100%;
        height: 32px;
        
        background: none;
        border: none;
        
        cursor: pointer;
        a, div{
            display: flex;
            align-items: center;
            justify-content: center;
            background: var( --echoPlaceholder);
            border-radius: 8px;
            color: var( --white );
            width: 100%;
            height: 100%;
            color: var( --white );
            text-decoration: none;
        }
        a.active{
            background: red;
        }
    }
    button > a.active{
        background: red;
    }
}