.ArrowUp, .ArrowDown{
    width: 6px;
    position: relative;
    padding-right: 3px;
}
.ArrowUp>span, .ArrowDown>span{
    bottom: -2px;
}

.ArrowUp>span, .ArrowDown>span{
    position: absolute;
    left: -5px;
}