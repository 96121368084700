.AnalyticItem{
    height: calc(160px - 24px);
    width: 352px;      
    background: var( --chartBackground );
    border-radius: 8px;
    padding: 12px;
    margin-right: 12px;
}
.TitleText{
    width: 100%;
    display: flex;
    justify-content: center;
    span{
        font-size: 10px;
        color: var( --caption);
    }
}
@media (max-width: 1550px) {
    .AnalyticItem{
        width: 280px; 
    }
}
@media (max-width: 1400px) {
    .AnalyticItem{
        width: 240px; 
    }
}
@media (max-width: 1250px) {
    .AnalyticItem{
        width: 100%; 
    }
}

@media (max-width: 1000px) {
    .AnalyticItem{
        width: 100%; 
    }
}