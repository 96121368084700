.NotificationsStatuses{
    width: 602px;
    max-height: 400px;
    min-height: 120px;
    overflow: auto;
    padding: 12px;
    .NotificationsStatusesItem{
        padding: 12px;
        background: var( --white );
        border-radius: 8px;
        position: relative;
        .notificationsRow:not(:first-child){
            margin-top: 8px;
        }
        .notificationsRow{
            display: flex;
            >*{
                margin-right: 8px;
            }
            span.NotificationsStatusesTitle{
                font-size: 14px;
            }
            span{
                font-size: 10px;
            }
        }
        .notificationsRowBetween{
            display: flex;
            justify-content: space-between;
        }
    }
    .NotificationsStatusesItem:not(:first-child){
        margin-top: 12px;
    }
    .status{
        height: 22px;
        width: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        svg{
            width: 14px;
            height: 14px;
            path{
                fill: var(--black);
            }
        } 
    }
    .period{
        span:first-child{
            color: var( --captionText )
        }
        span:not(:first-child){
            margin-left: 4px;
        }
    }
    .buttonsBlock{
        display: flex;
        >*{
            margin-left: 12px;
        }
    }
}

@keyframes border-pulsate {
    0% {
        //transform: scale(1.05);
        box-shadow: 5px 5px 1px 1px var( --blue ), 1px 1px 8px 10px var( --blue );
    }
    70% {
        //transform: scale(0.95);
        box-shadow: 5px 5px 1px 1px var( --background ), 1px 1px 8px 10px var( --background );
    }
    100% {
        //transform: scale(1.05);
        box-shadow: 5px 5px 1px 1px var( --blue ), 1px 1px 8px 10px var( --blue );
    }
}

.pulse {
    display: block;
    //margin: 20px auto;
    border-radius: 8px;
    box-shadow: 5px 5px 1px 1px var( --blue ), 1px 1px 8px 10px var( --blue );
    animation: border-pulsate 1.5s infinite;
}
.style{
    width: 100%;
    height: 100%;
    border: none;
    position: absolute;
    left: 0;
    top: 0;
}

@media only screen and (max-device-width: 800px), screen and (max-width: 800px) {
    .NotificationsStatuses{
        width: 100%;
    }
}