.ForgotPassword{
    width: 502px;
}

.ForgotPassword > *{
    margin-top: 24px;
}
.ForgotPasswordButton{
    display: flex;
    justify-content: center;
}
.ErrorMessageBlock{
    display: flex;
    justify-content: center;
}
.buttonsRow{
    display: flex;
    justify-content: center;
    button:not(:first-child){
        margin-left: 24px;
    }
}
.ErrorMessage{
    text-align: center;
    color: var( --red );
}