.App{
    width: 100%;
    height: 100%;
    position: relative;
}
.pageContainer{
    width: 100%;
    height: calc(100% - 100px);
    overflow-y: auto;
    margin-top: 112px;
    transition: margin 1s ease-in-out;
    background: var( --backgroundColor );
    padding: 0 2px;
}
.pageContainer.noScroll{
    overflow:unset;
}
.pageContainer.withPanel{
    height: calc(100% - 155px);
    margin-top: 185px;
}
.pageContainer.LeftMenu{
    margin-left: 324px;
    width: calc(100% - 300px);
}
.pageContainer.LeftMenu
.pageContainer.RightMenu{
    margin-right: 324px;
    width: calc(100% - 300px);
}
.pageContainer.LeftMenu.RightMenu{
    width: calc(100% - 660px)
}
.pageContainer.LeftMenuMobile{
    /*margin-left: 224px;
    width: calc(100% - 200px);*/
}
.pageContainer.LeftMenuBlock{
    position: relative;
    /*width: calc(100% - 200px);*/
}
:root{
    --background: var(--white);
    --moduleBackgroun: var( --lightGray)
}

.App .react-datepicker{
    display: flex;
}

.App  .react-datepicker__input-container{
    width: calc(100% - 5px);
    height: 32px;
    display: flex;
    align-items: center;
    background: var( --inputBackground );
    padding: 0 4px;
    border-radius: 8px;
    position: relative;
    cursor: pointer;
}
.App  .react-datepicker__input-container:after{
    content: '';
    position: absolute;
    top: 8px;
    width: 16px;
    height: 16px;
    right: 8px;
    background-image: url('./assets/icons/calendar.svg');
}
.App .react-datepicker__input-container input{
    border: none;
    background: none;
    width: calc(100% - 8px);
    font-size: 12px;
    cursor: pointer;
}
.App .react-datepicker__input-container input:active,
.App .react-datepicker__input-container input:focus{
    outline: none;
}
.App .react-datepicker__navigation--previous, .App .react-datepicker__navigation--next{
    top: 62px;
}
.App .react-datepicker__navigation--previous{
    left: 20px;
}
.App .react-datepicker__navigation--next{
    right: 20px;
}
.App .react-datepicker__header{
    background: var(--white);
}
.App .react-datepicker__month-container .react-datepicker__day{
    font-size: 12px;
    line-height: 25px;
    width: 32px;
 }
 .App .react-datepicker__month-container .react-datepicker__day--selected,  .App .react-datepicker__month-container .react-datepicker__day--in-range{
    background: var( --purple );
 }
 .App .react-datepicker__month-container .react-datepicker__day--in-range:hover{
    background: var( --mediumPurple);
 }
 .App .react-datepicker__day--in-selecting-range:not( .App .react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range) {
  background-color: var(--grey);
}
.App .react-datepicker__month-dropdown-container--scroll{
    width: 110px;
}
.App .react-datepicker__year-dropdown-container--scroll{
    width: 100px;
}
.App .react-datepicker__month-dropdown-container--scroll, .App .react-datepicker__year-dropdown-container--scroll{
    height: 28px;
    
    background: var(--white);
    border: 1px solid var(--gray);
    border-radius: 8px;
    font-size: 12px;
    position: relative;
}
.App .react-datepicker__year-dropdown{
    top: -2px;
    left: -1px;
    width: 100px;
}
.App .react-datepicker__month-dropdown{
    left: -22px;
    top: -2px;
    width: 150px;
}
.App .react-datepicker__month-dropdown, .App .react-datepicker__year-dropdown{
    background: var( --white );
    border-radius: 8px;
    
}
.App .react-datepicker__month-dropdown .react-datepicker__month-option:hover, .App .react-datepicker__year-option:hover{
    background: var( --blue );
    color: var( --white );
}
.App .react-datepicker__month-read-view, .react-datepicker__year-read-view{
    margin-top: 5px;
}

.App .react-datepicker__month-read-view--down-arrow, .App .react-datepicker__year-read-view--down-arrow{
    right: 12px;
}
.App .react-datepicker__month-container{
    border-radius: 8px;
    background: var(--white);
    width: 300px;
}
/*.App .react-datepicker__current-month{
    display: none;
}*/
.App .react-datepicker__month-container:last-child{
    margin-left: 12px;
    
}
.App .react-datepicker__month-container:last-child .react-datepicker__current-month{
    display: block;
}

.App .react-datepicker__portal .react-datepicker__day-name{
    width: 32px;
    font-size: 12px;
}

.App .react-datepicker__portal .react-datepicker__current-month{
    font-size: 12px;
}

.App .RightMenu #FiltersButtons{
    display: block;
}

.App .RightMenuMobile #FiltersButtons{
    display: block;
}

#FiltersButtons{
    display: none;
}

.FAILED{
    background: var( --red );
}
.COMPLETE{
    background: var( --green );
}
.IN_PROGRESS{
    background: var( --orange );
}
.PENDING{
    background: var( --blue );
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background: var( --selectedTableItem );
    border-radius: 4px;
    padding: 4px;
}
::-webkit-scrollbar-thumb {
    width: 8px;
    background-color: var( --scrollButton);
    
    border: 2px solid var( --selectedTableItem );
    border-radius: 8px;

    
}
::-webkit-scrollbar-thumb:hover {
    background: var(--scrollButtonHover);
}
::-webkit-scrollbar-thumb:vertical {
    border-width: 8px 2px;
}

svg path[data-path="stroke"]{
    stroke: var( --caption );
}
svg path[data-path="fill"]{
    fill: var( --caption );
}
svg circle[data-circle="fill"]{
    fill: var( --caption );
}
svg circle[data-circle="stroke"]{
    stroke: var( --caption );
}
svg rect[data-rect="stroke"]{
    stroke: var( --caption );
}

@media only screen and (max-device-width: 1200px), screen and (max-width: 1200px) {
    .pageContainer{
        margin-top: 100px;
    }
    .pageContainer.pageContainer.withPanel{
        margin-top: 190px;
    }
}

@media only screen and (max-device-width: 600px), screen and (max-width: 600px) {
    .pageContainer{
        margin-top: 50px;
    }
    .pageContainer.pageContainer.withPanel{
        margin-top: 140px;
    }
}