.ShortAnalytic{
    width: calc(100% - 48px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    .TopicBackgroundRow{
        display: flex;
        
    }
    .TopicBackground{
        height: calc(160px - 24px);
        width: calc(100% - 24px);      
        background: var( --chartBackground );
        border-radius: 8px;
        padding: 12px;
        margin-right: 12px;
        position: relative; 
    }
    .shorAnalyticWrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .AnalyticRow{
        display: grid;
        grid-template-columns: 300px repeat(3, 300px) 352px;
        gap: 20px;
        //justify-content: flex-start;
        margin-bottom: 12px;
        .analyticScrollBlock{
            display: contents;
        }
        .AnalyticHeaderItem, .AnalyticItem{
            width: 300px;
            border-radius: 8px;
            max-width: 300px;
        }
        .AnalyticHeaderItem{
            font-size: 14px;
            font-weight: 600;
            text-align: left;
            display: flex;
            align-items: center;
            justify-content: space-between;
            //span{
                color: var( --caption );
            //}
            
        }
        .AnalyticHeaderItem button{
            width: 250px;
        }
        .AnalyticHeaderItem button:hover span{
            color: var( --selectedButtonText ) !important
        }
        .AnalyticHeaderItem button.selected{
            color: var( --selectedButtonText ) !important
        }
        .AnalyticItem:not(:last-child), .AnalyticHeaderItem:not(:last-child){
            margin-right: 12px;
        }
    }
    .scrollButtonsBlock{
        display: none;
    }
}
.AnalyticItem{
    height: calc(160px - 12px);
    width: calc(300px - 24px);      
    background: var( --chartBackground );
    border-radius: 8px;
    padding: 12px 12px 0 12px;
    margin-right: 12px;
}

.mobileTopic{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 20;
    .mobileTopicDates{
        width: calc(100% - 16px);
        display: flex;
        justify-content: space-between;
        margin: 4px 0;

        span{
            font-size: 8px;
        }
    }
}

.mobileRowBlock{
    display: flex;
    flex-direction: column;


}

.rowBlock{
    display: grid;
    grid-template-columns: 290px repeat(3, 280px) 352px;

}

@media only screen and (max-device-width: 1600px), screen and (max-width: 1600px) {
    .ShortAnalytic{
        .AnalyticRow{
            grid-template-columns: 220px repeat(3, 252px) 264px;
            .AnalyticHeaderItem:first-child{
                width: 100%;
            }
            .AnalyticHeaderItem{
                width: 300px;
            }
            .AnalyticHeaderItem button{
                width: 200px;
            }
        }
    }
}

@media only screen and (max-device-width: 1500px), screen and (max-width: 1500px) {
    .ShortAnalytic{
        .AnalyticRow{
            grid-template-columns: 200px repeat(3, 255px) 352px;
            .AnalyticHeaderItem{
                width: 255px;
            }
            .AnalyticHeaderItem button{
                width: 120px;
            }
        }
    }
}

@media only screen and (max-device-width: 1400px), screen and (max-width: 1400px) {
    .ShortAnalytic{
        .AnalyticRow{
            grid-template-columns: 220px repeat(3, 252px) 264px;
            gap: 15px;
            .AnalyticHeaderItem:first-child{
                border-radius: 8px;
            }
            .AnalyticHeaderItem{
                width: 227px;
            }
            .AnalyticHeaderItem button{
                width: 100px;
            }
        }
    }
}

@media only screen and (max-device-width: 1300px), screen and (max-width: 1300px) {
    .ShortAnalytic{
        .AnalyticRow{
            grid-template-columns: 160px repeat(3, 251px) 225px;
            .AnalyticHeaderItem:first-child{
                width: 160px;
            }
            .AnalyticHeaderItem button{
                width: 100px;
            }
        }
    }
}

@media only screen and (max-device-width: 1200px), screen and (max-width: 1200px) {
    .ShortAnalytic{
        .AnalyticRow{
            grid-template-columns: 160px repeat(3, 251px) 207px;
            .AnalyticHeaderItem:first-child{
                width: 160px;
            }
            .AnalyticHeaderItem button{
                width: 100px;
            }
        }
    }
}

@media only screen and (max-device-width: 1100px), screen and (max-width: 1100px) {
    .ShortAnalytic{
        .AnalyticRow:not(:first-child){
            padding-top: 40px;
        }
        .AnalyticRow{
            grid-template-columns: 300px 300px 300px;
            align-items: flex-end;
            position: relative;
            .AnalyticHeaderItem:first-child{
                width: 160px;
                
            }
            .AnalyticHeaderItem button{
                width: 100px;
            }
            .analyticScrollBlock{
                display: flex;
                flex-direction: row-reverse;
                width: 299px;
                overflow-x: hidden;

            }
            //.AnalyticHeaderItem:nth-child(2),.AnalyticHeaderItem:nth-child(3),.AnalyticHeaderItem:nth-child(4), .AnalyticHeaderItem:nth-child(5){
                .AnalyticHeaderItem:nth-child(n+2):nth-child(-n+5){
            display: none;
            }
            .scrollButtonsBlock{
                width: 285px;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }
}

@media only screen and (max-device-width: 1000px), screen and (max-width: 1000px) {
    .ShortAnalytic{
        .AnalyticRow:not(:first-child){
            padding-top: 40px;
        }
        .AnalyticRow{
            grid-template-columns: 250px 250px 250px;
            .AnalyticHeaderItem:first-child{
                width: 160px;
                
            }
            .AnalyticHeaderItem button{
                width: 100px;
            }
            .analyticScrollBlock{
                display: flex;
                flex-direction: row-reverse;
                width: 250px;
                overflow-x: hidden;

            }
            //.AnalyticHeaderItem:nth-child(2),.AnalyticHeaderItem:nth-child(3),.AnalyticHeaderItem:nth-child(4), .AnalyticHeaderItem:nth-child(5){
            .AnalyticHeaderItem:nth-child(n+2):nth-child(-n+5){
                display: none;
            }
            .scrollButtonsBlock{
                width: 285px;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }
}

@media only screen and (max-device-width: 800px), screen and (max-width: 800px) {
    .ShortAnalytic{
        .shorAnalyticWrapper{
            align-items: flex-start;
        }
        .AnalyticRow{
            width: calc(100% - 32px);
            grid-template-columns: 40% 60%;
            .AnalyticHeaderItem:nth-child(n+2):nth-child(-n+4){
                display: none;
                }
            .analyticScrollBlock{
                width: 100%;
                overflow: visible;
            }
            .scrollButtonsBlock{
                width: 140px;
            }
        }
    }
}