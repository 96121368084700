.ModalReport{
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    .row{
        display: flex;
        align-items: center;
        margin-top: 12px;
        .blueText{
            margin-left: 8px;
            color: var( --blue);
            text-transform: uppercase;
        }
    }
    .label{
        font-size: 12px;
        color: var(--captionText)
    }
    .sendToEmail{
        margin-right: 12px;
    }

}