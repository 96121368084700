.ReportForm{
    display: flex;
    flex-direction: column;
    background: var( --background );
    border-radius: 8px;
    padding: 12px;
    .ReportTitle{
        font-size: 14px;
        font-weight: bold;
        color: var( --caption );
        padding: 18px;
    }
    .ReportSubTitle{
        width: 100%;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: var( --caption );
        padding: 18px;
    }
}
.ReportFormBlock{
    width: 800px;
    display: flex;
    flex-flow: row wrap;
    .FormPage{
        display: flex;
        flex-direction: column;
        width: 342px;
        height: 470px;
        background: var( --formBackground );
        box-shadow: 0px 0px 15px #00000055;
        padding: 8px;
        margin-left: 12px;
        margin-bottom: 12px;
    }
    .FormItem{
        width: calc(100% - 20px);
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 15px;
        background: var( --white);
        padding: 8px;
        border: 1px solid var( --captionText);
        border-radius: 8px;
        margin-bottom: 12px;
    }
    .TableOfContents{
        height: 242px
    }
    .ReportElement:not(:first-child){
        margin-left: 12px;
    }
    .ReportElement{
        width: 50%;
    }
    .ReportLine{
        width: 100%;
        display: flex;
    }
    .Colontitle{
        font-size: 12px;
    }
}