.AdminAddItem{
    display: flex;
    flex-direction: column;
    .formRow{
        padding: 6px 0;
    }
    .pages{
        display: flex;
        flex-flow: row wrap;
        div{
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: var( --purple);
            border-radius: 4px;
            color: var( --white );
            font-size: 12px;
            margin: 2px;
            cursor: pointer;
        }
        div:not(:first-child){
            margin-left: 4px;
        }
        .active{
            background: var( --darkPurple );
        }
    }
    .MarkerTopics{

        .markerTopicsList{
            max-height: 300px;
            overflow-y: auto;
        }
        table{
            width: 100%;
            th{
                color: var(--gray)
            }
            th, td{
                font-size: 10px;
                background: var(--white);
                padding: 4px 8px;
            }
            th:first-child, td:first-child, th:last-child, td:last-child{
                min-width: 16px;
                width: 16px;
                max-width: 16px;
            }
            th:nth-child(2), td:nth-child(2){
                width: 100%
            }
            tbody{
                
            }
        }
        .deleteButton{
            width: 16px;
            height: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: var(--backgroundLight);
            border: none;
            border-radius: 3px;
            padding: 4px;
            cursor: pointer;
        }

    }
    .searchBlock{
        display: flex;
        >*:not(:first-child){
            margin-left: 4px;
        }
    }
    .labelText{
        font-size: 12px;
        color: var( --captionText);
        padding: 8px;
        text-transform: uppercase;
    }
}