.KeywordsList{
    //width: calc(372px - 24px);
    //height: 350px;
    background:  var(--background);
    padding: 12px;
    border-radius: 8px;
    .KeywordsListHeader{
        display: flex;
        align-items: center;
        margin: 12px 0;
        .KeywordsListButtons{
            display: flex;
        }
        span{
            color: var( --caption )
        }
        .KeywordsTitle{
            font-size: 14px;
            font-weight: 900;
            font-family: Helvetica, Arial, sans-serif;
        }
    }
    .KeywordsListTable{
        width: 100%;
        height: 278px;
        overflow-y: auto;
        border-collapse: collapse;
        table{
            width: 100%;
            tr{
                th{
                    background: var(--chartTableItem);
                    font-size: 12px;
                    padding: 6px;
                    color: var( --caption )
                }
                td:first-child{
                    padding: 0 8px;
                }
                td{
                    background: var(--chartTableItem);
                    padding: 4px;
                    font-size: 12px;
                    text-align: center;
                    color: var( --caption );

                    .keyword{
                        padding: 2px 4px;
                        border-radius: 2px;
                    }
                    .KeywordsListPivots{
                        display: flex;
                        flex-direction: column;

                    }
                    .KeywordsListPivotsItem{
                        width: 46px;
                        height: 18px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 12px;
                        border-radius: 2px;
                        color: var(--white)
                    }
                    .KeywordsListPivotsItem:not(:first-child){
                        margin-top: 2px;
                    }
                    .NEUTRAL{
                        background: var( --echoSecondary);
                    }
                    .POSITIVE{
                        background: var( --echoGreen);
                    }
                    .NEGATIVE{
                        background: var( --echoRed);
                    }
                    .UNDEFINED{
                        background: var(--echoUndefined);
                    }
                }
            }
        }
    }
}