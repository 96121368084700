.DayMonthItem{
    display: flex;
    align-items: center;
    padding: 12px 0;
    > span{
        font-size: 12px;
    }
    .dayItem{
        width: 43px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var( --white);
        border-radius: 8px;
        border: 1px solid var( --gray );
        margin: 0 8px;
        span{
            font-size: 12px;
            color: var(--darkPurple);
        }
    }
}
.DayMonthItem:nth-child(odd){
    margin-right: 12px;
}
