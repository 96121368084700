.DigestViewItem{
    width: calc(100% - 24px);
    background: var( --background );
    padding: 12px;
    border-radius: 8px;
    .ItemRow{
        margin: 12px 0;
    }
    .ItemCol{
        width: 100%;
        max-height: 240px;
        min-height: 52px;
        overflow: auto;
        overflow-x: visible;
        /*
        overflow-y: auto;
        max-height: 240px;*/
    }
    .ItemCol{
        >*{
            margin-top: 12px;
        }
    }
    .Title{
        font-size: 12px;
        color: var( --caption)
    }
    .labelText{
        font-size: 12px;
        color: var( --captionText);
        padding-bottom: 8px;
        text-transform: uppercase;
    }
    .ItemShowBlock{
        margin-top: 4px;
        background: var( --white );
        border-radius: 8px;
        padding: 4px 8px;
        > span, .periods{
            font-size: 12px;
            color: var( --darkPurple)
        }
        .periods span:first-child{
            color: var( --captionText)
        }
    }
}