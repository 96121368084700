.TopicMenu{
    
    
    //
    .Checkboxes{
        background: var( --background );
        border-radius: 8px;
        padding: 12px;
        .ItemHeader{
            border-bottom: 1px solid var( --gray );
            padding: 8px 0;
        }
        .subHeader{
            margin-left: 12px;
            padding: 2px 0;
        }
        .MenuBody{
    
        }
        .CheckAllItem{
            display: flex;
        }
        .CheckAllSubItem{
            display: flex;
        }
        .CheckItem{
            display: flex;
            flex-direction: column;
            margin: 6px 0;
            span{
                margin-left: 6px;
                font-size: 12px;
            }
            .CheckItemName{
                display: flex;
            }
            .SubtopicItemName{
                margin-top: 6px;
                margin-left: 12px;
            }
        }
    }
    .Tree{
        display: flex;
        flex-direction: column;
        .topicItem{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 12px;
            button{
                display: flex;
                justify-content: space-between;
                align-items: center;
                background: var( --background);
                border: none;
                height: 32px;
                border-radius: 8px;
                font-size: 12px;
                cursor: pointer;
                width: 100%;
                text-align: left;
                svg{
                    path{
                        fill: white;
                    }
                }          
            }
            .topic{
                cursor: pointer;
            }
            .selected{
                background: var( --echoSecondary );
                color: var(--white)
            }
            .subtopic{
                margin-left: 12px;
            }
            .subtopicItem{
                display: flex;
                align-items: center;
                margin-top: 12px;
                margin-left: 12px;
                width: calc(100% - 12px);
                .subtopic-left{
                    margin: 12px;
                }
                .selectedSubtopic{
                    background: var( --echoSecondary );
                    color: var( --white );
                }
            }
        }
    }
}