.FiltersItem{
    width: calc(100% - 24px);
    background: var( --background );
    padding: 12px;
    border-radius: 8px;
    .ItemRow{
        margin: 12px 0;
    }
    .labelText{
        font-size: 10px;
        color: var( --captionText);
        padding-bottom: 8px;
        text-transform: uppercase;
    }
    .FiltersItemList{
        display: flex;
        flex-direction: column;
        background: var( --white);
        padding: 8px;
        border-radius: 8px;
        font-size: 12px;
        margin: 4px 0;
        color: var( --darkPurple );
        white-space: break-spaces;
    }
}