.PeriodCalendar{
    background: var( --background );
    padding: 12px;
    box-shadow: 0px 0px 15px #00000055;
    border-radius: 8px;
    position: relative;
    .PeriodDisplay{
        width: calc(100% - 16px);
        height: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: var(--white);
        border-radius: 8px;
        padding: 0 8px;
        cursor: pointer;
        .Period{
            font-size: 12px;
            .Separator{
                margin: 0 4px;
            }
        }
    }
}