.postsCount{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    div{
        background: var( --item );
        color: var( --white );
        padding: 4px 8px;
        border-radius: 8px;
        position: absolute;
        top: 1px;
        right: 25px;
        z-index: 17;
        cursor: pointer;
        svg{
            margin-left: 8px;
            path{
                fill: var( --white );
            }
        }
    }
}
.TopCount{
    padding-top: 30px;
    div{
        z-index: 0;
    }
}
.PostsItem{
    width: calc(100% - 8px);
    height: calc(100% - 60px);
    padding: 15px 0 0 4px;
    /*overflow-y: auto;*/
    position: relative;
    .postHeader{
        display: flex;
        align-items: center;
        > svg{
            margin-left: 12px;
            rect {
                fill: var( --echoSecondary );
            }
        }
        span{
            margin-left: 12px;
        }
    }
    .row{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
.PostsBlock{
    height: calc(100% - 10px);
    max-height: 100%;
    overflow-y: auto;
    padding-bottom: 20px;
    position: relative;
    .ShowMoreButton{
        position: fixed;
        right: 360px;
        bottom: 40px;
    }
}
.PostItem{
    width: calc(100% - 32px);
    background: var( --postBackground );
    border-radius: 8px;
    padding: 12px;
    margin: 12px 0;
    .postTitle{
        a{
            text-decoration: none;
            font-size: 18px;
            color: var( --postTitleText );
            cursor: pointer;
            font-weight: 800;
        }

    }
    .postText{
        font-size: 12px;
        color: var( --postText );
    }
    .PostItemAttributes{
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        
        margin: 12px 0;
        > .Attribute{
            display: flex;
            align-items: center;
            font-size: 11px;
            //color: var(--captionText);
            padding-right: 8px;
            text-transform: uppercase;
            svg{
                width: 12px;
            }
            cursor: help;
            >* {
                margin: 0 4px;
            }
            .authorName{
                font-weight: 600;
                color: var(--blue);
            }
        }
        > .Attribute:not(:first-child){
            padding-left: 4px;
        }
        > .Attribute:not(:last-child){
            border-right: 1px solid var( --gray );
        }
        
    }
    .AutoIcons{
        display: flex;
        >*{
            margin-left: 8px
        }
    }
    .icon{
        svg{
            width: 12px;
            height: 12px;
            path{
                stroke: var( --darkPurple)
            }
        }
    }
    .IconAttribute{
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var( --tableItem);
        border-radius: 4px;
        cursor: pointer;
        svg{
            width: 17px;
            height: 17px;
            margin: 0 !important;
            /*path{
                fill: var( --caption )
            }*/
        }
    }
    .IconAttribute:not(:first-child){
        margin-left: 4px;
    }
    .red{
        svg > path{
            fill: red
        }
    }
    .grey{
        svg > path{
            fill: var( --echoUndefined );
        }
    }
    .purple{
        svg > path{
            fill: var( --echoSecondary );
        }
    }
    .green{
        svg > path{
            fill: var(--green);
        }
    }
    .showMore{
        color: var( --blue );
        cursor: pointer;
    }
    .deletePostTypePanel{
        display: flex;
        flex-direction: column;
        position: relative;
        margin-left: 12px;
    }
    .deletedPanel{
        width: 100%;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: calc(100% + 4px );
        right: 0;
        background: var( --white );
        box-shadow: 0px 0px 15px #00000055;
        border-radius: 8px;
        z-index: 10;
        span{
            font-size: 12px;
            cursor: pointer;
            padding: 4px 8px;
        }
        span:hover{
            background: var( --echoSecondary );
            color: var( --white );
        }
        span:first-child{
            border-radius: 8px 8px 0 0;
        }
        span:last-child{
            border-radius: 0 0 8px 8px;
        }
    }
}
.PostItem:first-child{
    margin-top: 0;
}

.postTags{
    display: flex;
    //align-items: center;
    .tagItems{
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        .tagItem{
            display: flex;
            align-items: center;
            height: (24px - 8px);
            padding: 4px 8px;
            border-radius: 2px;
            font-size: 12px;
            margin-top: 8px;
        }
        .tagItem:not(:last-child){
            margin-right: 12px;
        }
        .tagItem:last-child{
            margin-right: 12px;
        }
    }
    
}
.controlButtons{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    >button:not(:first-child){
        margin-left: 12px;
    }
    button{
        margin-bottom: 4px;
    }
}
.ShowMoreButton{

}