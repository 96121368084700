.Signin{
    width: 100%;
    position: relative;
    .Header{
        width: 100%;
        height: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .titleText{
            display: flex;
            flex-direction: column;
            font-size: 22px;
            font-weight: 400;
            text-align: center;
            color: var(--caption)
        }
        svg{
            width: 350px;
            height: 48px;
            margin: 20px 0;
        }
    }
    .Body{
        .noPass{
            display: flex;
            justify-content: flex-end;
            font-size: 10px;
            font-weight: 400;
            line-height: 11.85px;
            color: var( --blue );
            margin-top: 8px;
            text-transform: uppercase;
            span{
                cursor: pointer;
            }
        }
    }
}
.checkboxRow{
    display: flex;
    align-items: center;
    span{
        font-size: 12px;
        color: var( --caption );
        margin-left: 12px;
    }
}
