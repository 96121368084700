.DefaultDays{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    .DefaultDaysButton{
        border: none;
        background: none;
        cursor: pointer;
        padding: 0;
        span{
            font-size: 12px;
            color: var( --blue );
        }
    }
    .DefaultDaysButton:disabled{
        cursor: progress;
    }
    .Separator:before{
        content: ' • ';
        color: var( --blue );
        margin: 0 2px;
    }
}