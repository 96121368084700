.DigestItem{
    width: calc(100% - 24px);
    background: var( --background );
    padding: 12px;
    border-radius: 8px;
    .ItemRow{
        margin: 12px 0;
    }
    .ItemCol{
        width: 100%;
        max-height: 240px;
        min-height: 52px;
        overflow: auto;
        overflow-x: visible;
        /*
        overflow-y: auto;
        max-height: 240px;*/
    }
    .ItemCol{
        >*{
            margin-top: 12px;
        }
    }
    .Title{
        font-size: 12px;
        color: var( --caption)
    }
    .timesBlockRow{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        > button{
            margin-left: 12px;
        }
    }
    .labelText{
        font-size: 10px;
        color: var( --captionText);
        padding-bottom: 8px;
        text-transform: uppercase;
    }.buttons{
        display: flex;
        justify-content: space-between;
        margin-top: 24px;
    }
}