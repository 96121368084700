.ControlButtons{
    display: flex;
    >*:not(:first-child){
        margin-left: 12px;
    }
    button{
        width: 32px;
        height: 32px;

        svg[data-path="fill"]{

        }
        svg{

        }
    }
    :disabled > svg > path, :disabled > svg > circle{
        stroke: var(--grey);
        
    }
}

@media only screen and (max-device-width: 1200px), screen and (max-width: 1200px) {
    .ControlButtons{
        width: 100px;
        flex-flow: row wrap;
        button{
            margin: 4px 0;
        }
        button:nth-child(odd){
            margin-left: 0;
        }
    }
}