.SwitchAdminMode{
    .modeButtons{
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
    }
    .mobileModeButtons{
        margin: 12px 0;
        button{
            margin-bottom: 12px;
        }
    }
    .toggleButton{
        width: 64px;
        height: 24px;
        background: var( --grey);
        border-radius: 8px;
        margin-bottom: 12px;
        position: relative;
        border: none;
        
        .runner{
            width: 50%;
            height: 100%;
            background: var( --echoSecondary);
            border: none;
            border-radius: 8px 0px 0px 8px;
            position: absolute;
            left: 0;
            transition: all 0.3s ease;
            cursor: pointer;
        }
    }
    .toggleButton.selected{
        .runner{
            border-radius: 0 8px 8px 0;
            left: 50%
        }
    }
}

