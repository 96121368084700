.TopicItem{
    width: calc(100% - 24px);
    background: var( --background );
    border-radius: 8px;
    //padding: 12px;
    .TopButtons{
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 12px 0;
    }
    .InputTitle{
        font-size: 12px;
    }
    .ItemRow{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        >div{
            width: 91px
        }
    }
    .Close{
        cursor: pointer;
    }
    .blueText{
        color: var(--blue)
    }
    .ReadOnlyBlock{
        display: flex;
        flex-direction: column;
        padding: 8px;
        background: var( --backgroundColor );
        border-radius: 8px;
        font-size: 12px;
        margin-bottom: 12px;
    }
    .showInstruction{
        cursor: pointer;
    }
}