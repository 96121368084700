.DropdownItem{
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    margin-top: 8px;
    .ItemBlock{
        display: flex;
        align-items: flex-start;
    }
    span{
        margin: 0 8px;
        color: var( --caption );
    }
    .ShowChilds{
        cursor: pointer;
    }
}

/*.DropdownItem.Child{
    margin-left: 12px;
}*/