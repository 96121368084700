.CheckoxItems{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    background: var(--white);
    padding: 8px;
    border-radius: 8px;
    .CheckboxHeader{
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        .showItemBlock{
            cursor: pointer;
        }
    }
    .ItemTitle{
        font-size: 14px;
        margin-bottom: 8px;
    }
    .ItemsList{
        max-height: 160px;
        overflow-y: auto;
    }
    .item{
        display: flex;
        margin: 4px 0;
        span{
            font-size: 12px;
            margin-left: 8px;
        }
    }
}